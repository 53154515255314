import React from "react"

const FrameCheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
    ...props
}) => {
    return (
        <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.7497 42.7503L19.9997 34.0003L17.083 36.917L28.7497 48.5837L53.7497 23.5837L50.833 20.667L28.7497 42.7503Z"
                fill="white"
            />
            <path
                d="M69 6.64398V1.00087L63.356 1.00087M63.356 69H68.9999L68.9999 63.3561M6.64293 1.0001L1.00014 1V6.64387M1 63.356V68.9992H6.64292"
                stroke="#0E9DE5"
                strokeWidth="2"
            />
        </svg>
    )
}

export default FrameCheckIcon
