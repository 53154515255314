import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import OptInSuccesssBody from "../sections/opt-in-success/OptInSuccesssBody"
import Layout from "../templates/Layout"
import bgImage from "../assets/BackgroundImages/opt-in-success/opt_in_success_main.png"
import OptInSuccessHelmet from "../components/SEO/OptInSuccessHelmet"

const optInSuccessPage = () => {
    return (
        <Layout>
            <OptInSuccessHelmet />
            <div className="bg-horizen-hp-bg">
                <div
                    className="bg-contain"
                    style={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <OptInSuccesssBody />
                    <NewsletterForm />
                </div>
            </div>
        </Layout>
    )
}

export default optInSuccessPage
