import React from "react"
import Helmet from "react-helmet"

const OptInSuccessHelmet = () => {
    return (
        <Helmet>
            <title>Thank you for subscribing!</title>
        </Helmet>
    )
}

export default OptInSuccessHelmet
