import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import FrameCheckIcon from "../../components/OptInSuccess/FrameCheckIcon"
import Typography from "../../components/Typography"
import ContentContainer from "../../templates/ContentContainer"

const OptInSuccesssBody = () => {
    return (
        <section>
            <ContentContainer>
                <div className="pt-24 h-[65vh] md:h-[700px] lg:h-[700px] flex flex-col justify-center items-center">
                    <FrameCheckIcon />
                    <Typography.H2 className="mt-8">
                        <FormattedMessage id="opt-in-success.heading" />
                    </Typography.H2>
                    <Typography.Body1 className="mt-2">
                        <FormattedMessage id="opt-in-success.body" />
                    </Typography.Body1>
                </div>
            </ContentContainer>
        </section>
    )
}

export default OptInSuccesssBody
